// @flow
import * as React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

import * as v from '../../config/variables'
import FormGroup from './FormGroup'
import Label from './Label'
import ValidationBox from './ValidationBox'

type Props = {
  ...Object,
  rounded?: boolean,
  inverted?: boolean,
}

const StyledInput = styled.input`
  display: block;
  width: 100%;
  border: none;
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  background-color: ${v.grayLighter};
  transition: box-shadow ease 0.2s;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }

  &[type='password'] {
    height: 40px;

    /* ${({ value }) =>
      // small-caption
      value.length &&
      `
      font: Verdana, small-caption;
      font-size: 25px;
    `}; */
  }
`

const RoundedInput = styled(StyledInput)`
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-size: 0.8rem;
  height: 40px;
  /* height: 47px; */
`

const TextInput = ({
  type,
  id,
  label,
  error,
  value,
  onChange,
  className,
  rounded = false,
  inverted = false,
  ...props
}: Props) => {
  return (
    <FormGroup>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>

      {rounded && (
        <RoundedInput
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          {...props}
        />
      )}

      {!rounded && (
        <StyledInput
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          {...props}
        />
      )}

      <ValidationBox error={error} inverted={inverted} />
    </FormGroup>
  )
}

export default TextInput
