// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children?: React.Node,
  // error: Object,
  // touched: boolean,
}

const StyledFormGroup = styled.div`
  margin: 0 0 1rem;
`

const FormGroup = (props: Props) => {
  const { children } = props

  return <StyledFormGroup>{children}</StyledFormGroup>
}

export default FormGroup
