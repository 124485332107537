// @flow
import React from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby' // graphql
import Img from 'gatsby-image'
import styled from 'styled-components'
import { parse } from 'qs'
import { transparentize } from 'polished'
import { Container } from 'reactstrap'
// import ReactMarkdown from 'react-markdown'

// Redux
// import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import ActionCreators from '../store/actions'

import { loggedIn } from '../lib/helper'
import * as v from '../config/variables'

// import { Button } from '../components/UI'
import LoginForm from '../components/LoginForm'
import Layout, { SEO } from '../components/Layout'

// type Props = {}

const LoginWrap = styled.div`
  padding: 0 0 0;

  @media (min-width: 576px) {
    padding: 2rem 0 0;
  }
`

const LoginBox = styled.div`
  margin: 1rem auto 0;
  padding: 0rem 1rem 1rem;
  max-width: 340px;
  min-height: 200px;
  /* background-color: ${transparentize(0.9, v.black)}; */
  /* box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.1); */
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 576px) {
    margin-top: 2rem;
  }
`

const Logo = styled(Img)`
  width: 120px;

  @media (min-width: 576px) {
    width: 140px;
  }
  /* position: absolute !important;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${v.white};
  border-radius: 50%;
  box-shadow: 0 0 0 3px ${v.white};

  img {
    margin: 0;
  } */
`

const Version = styled.p`
  color: ${v.orange};
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0.5rem 0 1.5rem;
`

const LoginPage = () => {
  // const loggedIn = user => (user || {}).isLoggedIn
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-tim-original-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          version
        }
      }
    }
  `)
  const {
    logo,
    site: {
      siteMetadata: { version },
    },
  } = data
  const defaultRedirectUrl = '/app'
  const { returnUrl } =
    typeof window !== 'undefined'
      ? parse(window.location.search, { ignoreQueryPrefix: true }) || {}
      : { returnUrl: null }

  const checkUserStatus = () => {
    if (loggedIn()) {
      navigate(returnUrl || defaultRedirectUrl)
      return null
    }
  }

  checkUserStatus()

  return (
    <Layout header={false} darkBackground>
      <Container>
        <SEO title="Login" />

        <LoginWrap>
          <LoginBox>
            <Logo fluid={logo.childImageSharp.fluid} />

            <Version>Aufgaben {version}</Version>
            {/* <Logo fixed={logo.childImageSharp.fixed} /> */}
            <LoginForm onLogin={() => checkUserStatus()} />
          </LoginBox>
        </LoginWrap>
      </Container>
    </Layout>
  )
}

export default LoginPage

// function mapStateToProps({ user }) {
//   return { user }
// }

// function mapDispatchToProps(dispatch: Function) {
//   return Object.assign(
//     { dispatch },
//     bindActionCreators(ActionCreators, dispatch)
//   )
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(LoginPage)

// export const query = graphql`
//   query {
//     logo: file(relativePath: { eq: "logo-tim-orinal-white.png" }) {
//       childImageSharp {
//         fixed(width: 80) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
