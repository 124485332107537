// @flow
import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import validationSchema from './validationSchema'
import * as v from '../../config/variables'

import { Button } from '../UI'
import { TextInput, ValidationBox } from '../Form'

type Props = {
  loginUser: Function,
  onLogin: Function,
}

const StyledLoginForm = styled(Formik)``

const initialValues = {
  username: '',
  password: '',
}

const StyledForm = styled(Form)`
  margin: 0;
  width: 100%;
`

const SubmitButton = styled(Button)`
  width: 100%;
  border-radius: 50px;
  background-color: ${v.orange};
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: 1rem;

  &:disabled,
  &.disabled {
    opacity: 0.7;
    background-color: ${v.orange};
    /* color: ${v.gray600}; */
  }
`

// const LinkBox = styled.aside`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   /* align-items: center; */
//   margin: 3rem 0 0;
//   font-size: 0.8rem;

//   p {
//     color: ${v.gray};
//     margin: 0 0 0.25rem;
//     text-align: center;

//     a {
//       white-space: pre;
//     }
//   }
// `

const GeneralValidationBox = styled(ValidationBox)`
  margin: 1rem 0 0;
`

const LoginForm = (props: Props) => {
  const [loading, setLoading] = useState(false)
  // const [password, setPassword] = useState('')

  const handleLogin = (values, setSubmitting, setFieldError) => {
    if (loading) return

    const { loginUser, onLogin } = props
    const { username, password } = values
    setLoading(true)

    loginUser({ username, password })
      .then(() => {
        setLoading(false)
        setSubmitting(false)
        onLogin()
      })
      .catch(({ code }) => {
        setLoading(false)
        setSubmitting(false)

        if (code === 100 || code === 4) {
          setFieldError(
            'general',
            'Nutzername und Passwort stimmen nicht überein.'
          )
          // actions.setFieldValue('password', '')
          // actions.setTouched(false)
        } else {
          setFieldError(
            'general',
            'Ein Fehler ist aufgetreten – bitte versuchen Sie es später nochmal.'
          )
        }
      })
  }

  return (
    <StyledLoginForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) =>
        handleLogin(values, setSubmitting, setFieldError)
      }
    >
      {formProps => {
        const {
          values,
          handleSubmit,
          handleChange,
          // handleBlur,
          errors,
          touched,
          // isSubmitting,
          // isValid,
          // submitCount,
        } = formProps
        const { username, password } = values

        return (
          <StyledForm onSubmit={handleSubmit}>
            <TextInput
              id="username"
              type="text"
              placeholder="Nutzername/E-Mail-Adresse"
              value={username}
              error={touched.username && errors.username}
              onChange={handleChange}
              // onBlur={handleBlur}
              rounded
              inverted
            />

            <TextInput
              id="password"
              type="password"
              placeholder="Passwort"
              value={password}
              error={touched.password && errors.password}
              onChange={handleChange}
              // onBlur={handleBlur}
              rounded
              inverted
            />

            <SubmitButton type="submit" disabled={loading}>
              {!loading ? 'Anmelden' : 'Bitte warten…'}
            </SubmitButton>

            <GeneralValidationBox error={errors.general} inverted />

            {/* <LinkBox>
              <p>
                Noch nicht registriert?{' '}
                <a
                  href="/register"
                  onClick={e => e.preventDefault()}
                  title="Jetzt registrieren"
                >
                  Jetzt registrieren
                </a>
              </p>
              <p>
                <a
                  href="/reset-password"
                  onClick={e => e.preventDefault()}
                  title="Passwort vergessen"
                >
                  Passwort vergessen
                </a>
              </p>
            </LinkBox> */}
          </StyledForm>
        )
      }}
    </StyledLoginForm>
  )
}

function mapStateToProps({ user }) {
  return { user }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)
