// @flow
import React from 'react'
import styled from 'styled-components'

import * as v from '../../config/variables'

type Props = {
  error: Object,
  inverted?: boolean,
  // touched: boolean,
}

const ErrorMessage = styled.div`
  color: ${({ inverted }) => (inverted ? v.white : v.orange)};
  font-size: 0.75rem;
  margin: 0.2rem 0 0.2rem;
`

const ValidationBox = (props: Props) => {
  const { error } = props

  return error ? <ErrorMessage {...props}>{error}</ErrorMessage> : null
}

export default ValidationBox
